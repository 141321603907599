<template>
    <v-container>
        <base-hero-simple class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>
        <div class="container-bg">
            <div class="container-fg">
                <v-card-title style="padding: 5px 5px">
                    <div style="display: flex; flex-direction: row">
                        <img style="width: 130px; margin-bottom: 1%" src="../../../assets/logos/getac-logo.svg" />
                        <div class="text-h7 nav-font pl-3" style="margin-top: 8px; font-weight: 400">DRIVERS</div>
                    </div>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <v-expansion-panels v-model="panel" class="expanded-panels" dark mandatory accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="expansion-header-first" expand-icon="">
                            <h1 style="font-size: 36px; letter-spacing: -0.08rem"> View Drivers </h1>
                            <v-text-field
                                v-model="serialNumber"
                                outlined
                                dense
                                label="Enter serial number"
                                style="margin-top: 25px; width: 10px; margin-right: 50px"
                            ></v-text-field>
                            <v-btn
                                max-width="200px"
                                color="primary"
                                elevation="0"
                                :disabled="!serialNumber"
                                @click="queryDrivers"
                                >Submit</v-btn
                            >
                        </v-expansion-panel-header>
                        <v-alert v-if="noResults" border="bottom" colored-border type="warning" elevation="2">
                            Serial may have customized BIOS. Please reach out to support.
                        </v-alert>
                        <v-expansion-panel-content style="padding: 25px; border-radius: 0px" class="container-fg">
                            <v-data-table
                                id="request-data-table"
                                dark
                                :items-per-page="10"
                                :headers="headers"
                                :items="drivers"
                                :search="search"
                                :loading="$apollo.loading || loading"
                                no-data-text="No results available"
                                no-results-text="No results available"
                            >
                                <template v-slot:item.action="{ item }">
                                    <v-btn outlined @click="downloadDriver(item)">Download</v-btn>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" color="white" @click="snackbar = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { navLinks } from '../navigation.js'
import { getDrivers } from '@/api'
import gql from 'graphql-tag'

export default {
    metaInfo: { title: `Drivers`, subTitle: `Account Settings` },
    name: 'Drivers',
    props: {},
    data() {
        return {
            serialNumber: '',
            search: '',
            panel: 0,
            links: navLinks,
            drivers: [],
            loading: false,
            noResults: false,
            snackbar: false,
            snackbarMessage: '',
            headers: [
                {
                    text: 'Description',
                    value: 'DESCRIPTION'
                },
                {
                    text: 'Product Category Name',
                    value: 'PRODUCTCATEGORYNAME'
                },
                {
                    text: 'Last Updated',
                    value: 'LASTUPDATETIME'
                },
                {
                    text: 'Size',
                    value: 'FILESIZE'
                },
                {
                    text: '',
                    value: 'action'
                }
            ]
        }
    },
    methods: {
        async driversBySn() {
            await getDrivers({
                serialNumber: this.serialNumber
            })
                .then(({ data }) => {
                    console.log(data)
                })
                .catch((e) => {})
        },
        queryDrivers() {
            this.loading = true
            this.$apollo
                .query({
                    query: gql`
                        query queryURL($url: String!, $method: String) {
                            queryURL(url: $url, method: $method)
                        }
                    `,
                    variables: {
                        url: `https://rma.getac.com/WEB/biosDriverAction.do?method=getFilesBySn&serial_number=${this.serialNumber}`,
                        method: 'GET'
                    }
                })
                .then((data) => {
                    const result = JSON.parse(data.data.queryURL)
                    this.drivers = result.length > 0 && result.some((e) => e !== null) ? result : []
                    this.loading = false
                    if (this.drivers.length <= 0) {
                        this.noResults = true
                        this.snackbar = true
                        this.snackbarMessage = 'No results'
                    } else {
                        this.noResults = false
                    }
                })
        },
        downloadDriver(item) {
            if (item.URL) {
                window.location.assign(item.URL)
            } else {
                window.location.assign(
                    `https://support.getac.com/Service/FileReader/Index?fileid=${item.FILEID}&cateid=${item.PRODUCTCATEGORYID}`
                )
            }
        }
    }
}
</script>
